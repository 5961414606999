import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  public setCookie(name: string, value: string, days: number): void {
    const date: Date = new Date();

    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

    const expires: string = 'expires=' + date.toUTCString();

    document.cookie = name + '=' + value + ';' + expires + ';path=/';
  }

  public getCookie(name: string): string {
    const nameEqual: string = name + '=';
    const cookieList: Array<string> = document.cookie.split(';');

    let result: string = '';

    cookieList.forEach((cookie: string) => {
      const trimmedCookie: string = cookie.trim();

      if (trimmedCookie.indexOf(nameEqual) === 0) {
        result = trimmedCookie.substring(nameEqual.length, trimmedCookie.length);
      }
    });

    return result;
  }

  public removeCookiesByPattern(pattern: string): void {
    const regex: RegExp = new RegExp(pattern);
    const cookies: Array<string> = document.cookie.split(';');

    cookies.forEach((cookie: string) => {
      const key: string = cookie.trim().split('=')[0];

      if (regex.test(key)) {
        document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
      }
    });
  }
}

import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { SectionNameEnum } from '../../enums/section-name/section-name.enum';
import { SectionNameModel } from '../../models/section-name/section-name.model';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { CookiesService } from '../cookies/cookies.service';

@Injectable({
  providedIn: 'root',
})
export class SatisfactionFeedbackScriptService {
  private renderer: Renderer2;
  private elementRef: ElementRef | null;
  private readonly scriptForDashboardPath: string;

  constructor(
    private rendererFactory: RendererFactory2,
    private localStorageService: LocalStorageService,
    private cookiesService: CookiesService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.elementRef = null;
    this.scriptForDashboardPath = 'assets/js/customer-satisfaction-feedback-dashboard/customer-satisfaction-feedback-dashboard.min.js';
  }

  public loadSatisfactionFeedbackScript(sectionName: SectionNameModel, elementRef: ElementRef): void {
    this.elementRef = elementRef;

    const scriptElement: HTMLElement | null = this.getIsSatisfactionFeedbackScriptElement();

    this.clearLocalstorageAndCookiesStartquestionSession();

    if (!!scriptElement) {
      this.renderer.removeChild(this.elementRef.nativeElement, scriptElement);
    }

    switch (sectionName) {
      case SectionNameEnum.dashboard:
      default:
        return this.injectScriptToPage(this.scriptForDashboardPath);
    }
  }

  private clearLocalstorageAndCookiesStartquestionSession(): void {
    const pattern: string = 'startquestion';

    this.localStorageService.removeLocalStorageItemsByPattern(pattern);
    this.cookiesService.removeCookiesByPattern(pattern);
  }

  private injectScriptToPage(scriptSrc: string): void {
    const script: HTMLScriptElement = this.renderer.createElement('script');

    script.id = 'satisfactionFeedbackScript';
    script.type = 'text/javascript';
    script.src = scriptSrc;
    script.async = true;
    script.defer = true;

    if (!this.elementRef) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    this.renderer.appendChild(this.elementRef.nativeElement.ownerDocument.body, script);
  }

  private getIsSatisfactionFeedbackScriptElement(): HTMLElement | null {
    return document.querySelector('#satisfactionFeedbackScript');
  }
}
